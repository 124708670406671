import type { Notifier } from '@airbrake/browser'

export async function notifyError (airbrake: Notifier, message: string, error?: Error) {
  try {
    const noticeId = await airbrake.notify({
      message,
      error,
      userAgent: window.navigator?.userAgent || '',
      screen: {
        width: `${window.screen?.width || 0} px (${window.screen?.availWidth || 0} px)`,
        height: `${window.screen?.height || 0} px (${window.screen?.availHeight || 0} px)`,
        orientation: window.screen?.orientation?.type || ''
      }
    })

    if (!noticeId) {
      throw new Error('noticeId is empty')
    }
  } catch (err) {
    const error = err as Error
    console.error('[notifyError] Error', error)
  }
}