export function initIntervalIds (intervalIds: number[] = []) {
  if (Array.isArray(window.intervalIds)) {
    window.intervalIds.concat(intervalIds)
    return
  }

  window.intervalIds = intervalIds.length > 0 ? intervalIds : []
}

export function clearIntervalIds (intervalIds: number[] = []): void {
  let ids: number[] = []

  if (intervalIds.length > 0) {
    ids = intervalIds
  } else if (Array.isArray(window.intervalIds)) {
    ids = window.intervalIds
  }

  if (ids.length === 0) {
    return
  }

  for (const id of ids) {
    window.clearInterval(id)
  }

  window.intervalIds = []
}

export function initTimeoutIds (timeoutIds: number[] = []) {
  if (Array.isArray(window.timeoutIds)) {
    window.timeoutIds.concat(timeoutIds)
    return
  }

  window.timeoutIds = timeoutIds.length > 0 ? timeoutIds : []
}

export function clearTimeoutIds (timeoutIds: number[] = []): void {
  let ids: number[] = []

  if (timeoutIds.length > 0) {
    ids = timeoutIds
  } else if (Array.isArray(window.timeoutIds)) {
    ids = window.timeoutIds
  }

  if (ids.length === 0) {
    return
  }

  for (const id of ids) {
    window.clearTimeout(id)
  }

  window.timeoutIds = []
}
