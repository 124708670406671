export default defineNuxtPlugin(() => {
  const router = useRouter()
  const loading = useLoadingIndicator()

  router.beforeEach((to, from, next) => {
    // "<nuxt-loading-indicator />" component is needed to work
    loading.start()
    next()
  })

  router.afterEach(() => {
    loading.finish()
  })
})
