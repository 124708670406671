import { customAlphabet } from 'nanoid'

const numbers = '1234567890'
const lowerLetters = 'abcdefghijklmnopqrstuvwxyz'
const allCharacters = numbers + lowerLetters

export function generateUuid (uuidSize: number) {
  const nanoid = customAlphabet(allCharacters, uuidSize)
  return nanoid()
}
