<template>
  <nuxt-layout>
    <nuxt-page />
  </nuxt-layout>
</template>

<script setup lang="ts">
import type { Unsubscribe } from 'firebase/firestore'
import type { ScreenData } from 'shared-types/screen'

const wakeLock = useWakeLock()

const nuxtApp = useNuxtApp()
const route = useRoute()

const configId = useState<string|null>('configId', () => {
  return window.localStorage.getItem('configId')
})
const screenCode = useState<string|null>('screenCode', () => {
  return window.localStorage.getItem('screenCode')
})
const screenData = useState<ScreenData|null>('screenData', () => {
  const data = window.localStorage.getItem('screenData')
  return data ? JSON.parse(data) : null
})

const hasPreview = computed(() => {
  return typeof route.query.preview !== 'undefined'
})

const stopListening = ref<Unsubscribe|null>(null)

const airbrake = nuxtApp.$airbrake
const firestore = nuxtApp.$firebase.firestore
const collectionName = 'screens'

let previousConfigId: string|null = null

function storeScreenCode (code: string): string {
  window.localStorage.setItem('screenCode', code)
  return code
}

function storeScreenData (docData: ScreenData): ScreenData {
  const strDocData = JSON.stringify(docData)
  window.localStorage.setItem('screenData', strDocData)

  return docData
}

function cleanScreenData () {
  window.localStorage.removeItem('screenData')
  screenData.value = null
}

watch(configId, async () => {
  if (
    hasPreview.value ||
    !screenCode.value ||
    !configId.value ||
    configId.value === previousConfigId
  ) {
    return
  }

  previousConfigId = configId.value

  const screenId = `${configId.value}${screenCode.value}`

  await listenDocumentChanges<ScreenData>(
    firestore,
    collectionName,
    screenId,
    async (docData, unsubscribe, listenerError) => {
      try {
        if (!stopListening.value) {
          stopListening.value = unsubscribe
        }

        if (listenerError) {
          const message = `Listen screen (${screenId}) changes listener error`

          console.error(message, listenerError)
          await notifyError(airbrake, message, listenerError)
          return
        }

        if (!configId.value || docData) {
          cleanScreenData()
        }

        if (!configId.value) {
          await navigateTo('/', {
            replace: true
          })
          return
        }

        if (!docData) {
          await navigateTo(`/${configId.value}`, {
            replace: true
          })
          return
        }

        const newPlaylistId = docData.playlistId
        const currPlaylistId = screenData.value?.playlistId

        screenData.value = storeScreenData(docData)

        if (newPlaylistId === currPlaylistId) {
          return
        }

        await navigateTo(`/playlists/${newPlaylistId}`, {
          replace: true
        })
      } catch (err) {
        const error = err as Error
        const message = `Listen screen (${screenId}) changes global error`

        console.error(message, error)
        await notifyError(airbrake, message, error)
      }
    }
  )
}, {
  immediate: true
})

async function requestWakeLock () {
  const { isActive, isSupported, request } = wakeLock
  const logScope = '[requestWakeLock]'

  try {
    console.log(`${logScope} Wake lock before`, {
      isActive: isActive.value,
      isSupported: isSupported.value
    })

    if (isActive.value) {
      return
    }

    if (isSupported.value) {
      await request('screen')
      return
    }

    await notifyError(airbrake, 'Screen Wake Lock API is not supported')
  } catch (err) {
    const error = err as Error
    console.error(`${logScope} Error`, error)

    await notifyError(airbrake, 'Screen Wake Lock API request error', error)
  } finally {
    console.log(`${logScope} Wake lock after`, {
      isActive: isActive.value,
      isSupported: isSupported.value
    })
  }
}

onMounted(() => {
  // Request the screen to deactivate its sleep mode
  requestWakeLock().then(() => {
    document.addEventListener('visibilitychange', async () => {
      if (document.visibilityState !== 'visible') {
        return
      }

      await requestWakeLock()
    })
  })

  initIntervalIds()
  initTimeoutIds()
})

onBeforeMount(async () => {
  if (hasPreview.value) {
    return
  }

  if (!screenCode.value) {
    const code = generateUuid(8)
    screenCode.value = storeScreenCode(code)
  }

  if (screenData.value?.playlistId) {
    await navigateTo(`/playlists/${screenData.value.playlistId}`, {
      replace: true
    })
  }
})

onBeforeUnmount(() => {
  if (!stopListening.value) {
    return
  }

  stopListening.value()
})
</script>
