import { Notifier } from '@airbrake/browser'

export default defineNuxtPlugin(nuxtApp => {
  // Staging or production only
  if (process.dev) {
    return
  }

  const config = useRuntimeConfig()
  const env = config.public

  if (
    !env.AIRBRAKE_PROJECT_ID ||
    !env.AIRBRAKE_PROJECT_KEY
  ) {
    return
  }

  const airbrake = new Notifier({
    projectId: Number(env.AIRBRAKE_PROJECT_ID),
    // @ts-expect-error
    projectKey: env.AIRBRAKE_PROJECT_KEY
  })

  if (window) {
    window.airbrake = airbrake
  }

  nuxtApp.provide('airbrake', airbrake)

  nuxtApp.hook('app:error', error => {
    airbrake.notify({ error })
  })
})
