import {
  Firestore, writeBatch, collection, doc,
  addDoc, setDoc, updateDoc, deleteDoc, getDoc, getDocs,
  query, where, orderBy, limit, startAfter, endAt,
  documentId, getCountFromServer, onSnapshot
} from 'firebase/firestore'

import type { QueryConstraint } from 'firebase/firestore'
import type { Document, Documents, DocumentFilter, ListenerCallback } from 'shared-types/database'
import type { AnyObject } from 'shared-types/value'

/**
 * Generate a document id
 * @see https://firebase.google.com/docs/reference/js/firestore_.collectionreference
 * @see https://firebase.google.com/docs/reference/js/firestore_?hl=fr#doc
 * @param {Firestore} database
 * @param {string} collectionName
 * @returns {string} Document ID
 */
export function generateDocumentId (
  database: Firestore,
  collectionName: string
): string {
  const colRef = collection(database, collectionName)
  const docRef = doc(colRef)

  return docRef.id
}

/**
 * Add a document into a collection
 * @see https://firebase.google.com/docs/reference/js/firestore_.collectionreference
 * @see https://firebase.google.com/docs/reference/js/firestore_.md#adddoc
 * @param {Firestore} database
 * @param {string} collectionName
 * @param {T} documentData
 * @returns {Promise<string|null>} Document ID (or null)
 */
 export async function addDocument <T extends AnyObject>(
  database: Firestore,
  collectionName: string,
  documentData: T
): Promise<string|null> {
  try {
    const hasDocumentId = !!documentData.id
    const colRef = collection(database, collectionName)
    const docData = { ...documentData }
    const docRef = hasDocumentId && typeof documentData.id === 'string'
      ? doc(colRef, documentData.id)
      : await addDoc(colRef, docData)
    const docId = docRef.id

    if ('id' in docData) {
      delete docData.id
    }

    if (hasDocumentId) {
      await setDoc(docRef, docData)
    }

    console.log('[addDocument] Firestore result', docId)

    return docId
  } catch (error) {
    console.error('[addDocument] Firestore error', error)
    return null
  }
}

/**
 * Add multiple documents into a collection
 * @see https://firebase.google.com/docs/reference/js/firestore_.writebatch#writebatchset
 * @see https://firebase.google.com/docs/reference/js/firestore_.collectionreference
 * @see https://firebase.google.com/docs/reference/js/firestore_#doc_2
 * @param {Firestore} database
 * @param {string} collectionName
 * @param {T[]} documentsData
 * @returns {Promise<string[]|null>} Documents ID (or null)
 */
 export async function addDocuments <T extends AnyObject>(
  database: Firestore,
  collectionName: string,
  documentsData: T[]
): Promise<string[]|null> {
  try {
    const docIds: string[] = []
    const batch = writeBatch(database)
    const colRef = collection(database, collectionName)

    for (const documentData of documentsData) {
      const docRef = doc(colRef)

      docIds.push(docRef.id)
      batch.set(docRef, { ...documentData })
    }

    await batch.commit()

    console.log('[addDocuments] Firestore result', docIds)

    return docIds
  } catch (error) {
    console.error('[addDocuments] Firestore error', error)
    return null
  }
}

/**
 * Update a document into a collection
 * @see https://firebase.google.com/docs/reference/js/firestore_#doc
 * @see https://firebase.google.com/docs/reference/js/firestore_#updatedoc
 * @param {Firestore} database
 * @param {string} collectionName
 * @param {T} document
 * @returns {Promise<string|null>}
 */
 export async function updateDocument <T extends Document>(
  database: Firestore,
  collectionName: string,
  document: T
): Promise<string|null> {
  try {
    const docRef = doc(database, collectionName, document.id)
    const documentData: Omit<Document, 'id'> = { ...document }

    delete documentData.id

    await updateDoc(docRef, documentData)

    console.log('[updateDocument] Firestore result', docRef.id)

    return docRef.id
  } catch (error) {
    console.error('[updateDocument] Firestore error', error)
    return null
  }
}

/**
 * Update multiple documents into a collection
 * @see https://firebase.google.com/docs/reference/js/firestore_.writebatch#writebatchupdate
 * @see https://firebase.google.com/docs/reference/js/firestore_#doc
 * @param {Firestore} database
 * @param {string} collectionName
 * @param {T[]} documents
 * @returns {Promise<string[]|null>}
 */
 export async function updateDocuments <T extends Document>(
  database: Firestore,
  collectionName: string,
  documents: T[]
): Promise<string[]|null> {
  try {
    const docIds: string[] = []
    const batch = writeBatch(database)

    for (const document of documents) {
      const docRef = doc(database, collectionName, document.id)
      const documentData: Omit<Document, 'id'> = { ...document }

      delete documentData.id

      docIds.push(docRef.id)
      batch.update(docRef, documentData)
    }

    await batch.commit()

    console.log('[updateDocuments] Firestore result', docIds)

    return docIds
  } catch (error) {
    console.error('[updateDocuments] Firestore error', error)
    return null
  }
}

/**
 * Delete a document into a collection
 * @see https://firebase.google.com/docs/reference/js/firestore_#doc
 * @see https://firebase.google.com/docs/reference/js/firestore_.md#deletedoc
 * @param {Firestore} database
 * @param {string} collectionName
 * @param {string} documentId
 * @returns {Promise<string|null>}
 */
 export async function deleteDocument (
  database: Firestore,
  collectionName: string,
  documentId: string
): Promise<string|null> {
  try {
    const docRef = doc(database, collectionName, documentId)

    await deleteDoc(docRef)

    console.log('[deleteDocument] Firestore result', docRef.id)

    return docRef.id
  } catch (error) {
    console.error('[deleteDocument] Firestore error', error)
    return null
  }
}

/**
 * Delete multiple documents into a collection
 * @see https://firebase.google.com/docs/reference/js/firestore_.writebatch#writebatchdelete
 * @see https://firebase.google.com/docs/reference/js/firestore_#doc
 * @param {Firestore} database
 * @param {string} collectionName
 * @param {string[]} documentIds
 * @returns {Promise<string[]|null>}
 */
 export async function deleteDocuments (
  database: Firestore,
  collectionName: string,
  documentIds: string[]
): Promise<string[]|null> {
  try {
    const docIds: string[] = []
    const batch = writeBatch(database)

    for (const documentId of documentIds) {
      const docRef = doc(database, collectionName, documentId)

      docIds.push(docRef.id)
      batch.delete(docRef)
    }

    await batch.commit()

    console.log('[deleteDocuments] Firestore result', docIds)

    return docIds
  } catch (error) {
    console.error('[deleteDocuments] Firestore error', error)
    return null
  }
}

/**
 * Soft delete a document into a collection
 * @param {Firestore} database
 * @param {string} collectionName
 * @param {string} documentId
 * @returns {Promise<string|null>}
 */
export function softDeleteDocument <T extends Document>(
  database: Firestore,
  collectionName: string,
  documentId: string
): Promise<string|null> {
  const currDatetime = new Date()
  const isoDatetime = currDatetime.toISOString()
  const documentData = {
    id: documentId,
    deletionDate: isoDatetime,
    isDeleted: true
  } as unknown as T

  return updateDocument<T>(database, collectionName, documentData)
}

/**
 * Soft delete multiple documents into a collection
 * @param {Firestore} database
 * @param {string} collectionName
 * @param {string[]} documentIds
 * @returns {Promise<string[]|null>}
 */
 export async function softDeleteDocuments (
  database: Firestore,
  collectionName: string,
  documentIds: string[]
): Promise<string[]|null> {
  try {
    const promises: Promise<string|null>[] = []

    for (const documentId of documentIds) {
      promises.push(
        softDeleteDocument(database, collectionName, documentId)
      )
    }

    const responses = await Promise.allSettled(promises)
    const docIds = responses.filter(({ status }) => status === 'fulfilled').map(res => {
      return (res as PromiseFulfilledResult<string>).value
    })

    if (!docIds) {
      throw new Error('"docIds" is empty')
    }

    console.log('[softDeleteDocuments] Firestore result', docIds)

    const rejectedResponses = responses.filter(({ status }) => status === 'rejected')

    if (rejectedResponses.length > 0) {
      console.warn('[softDeleteDocuments] Firestore rejected responses', rejectedResponses)
    }

    return docIds
  } catch (error) {
    console.error('[softDeleteDocuments] Firestore error', error)
    return null
  }
}

/**
 * Retrieve a document into a collection
 * @see https://firebase.google.com/docs/reference/js/firestore_#doc
 * @see https://firebase.google.com/docs/reference/js/firestore_.md#getdoc
 * @param {Firestore} database
 * @param {string} collectionName
 * @param {string} documentId
 * @returns {Promise<T|null>}
 */
 export async function getDocument <T extends Document>(
  database: Firestore,
  collectionName: string,
  documentId: string
): Promise<T|null> {
  try {
    const docRef = doc(database, collectionName, documentId)
    const docSnap = await getDoc(docRef)
    const docData = docSnap.data()

    if (!docData) {
      console.warn('[getDocument] Firestore document not exists', documentId)
      return null
    }

    const documentData = { id: docSnap.id, ...docData } as T

    console.log('[getDocument] Firestore result', documentData)

    return documentData
  } catch (error) {
    console.error('[getDocument] Firestore error', error)
    return null
  }
}

/**
 * Retrieve multiple documents into a collection
 * @see https://firebase.google.com/docs/reference/js/firestore_.collectionreference
 * @see https://firebase.google.com/docs/reference/js/firestore_.md#query
 * @see https://firebase.google.com/docs/reference/js/firestore_.md#where
 * @see https://firebase.google.com/docs/reference/js/firestore_.md#getdocs
 * @see {@link https://stackoverflow.com/a/70388625/10531083|documentId}
 * @param {Firestore} database
 * @param {string} collectionName
 * @param {string[]} documentIds
 * @param {Pick<DocumentFilter, 'sort'>} [documentFilter]
 * @returns {Promise<T[]|null>}
 */
 export async function getDocuments <T extends Document>(
  database: Firestore,
  collectionName: string,
  documentIds: string[],
  documentFilter?: Pick<DocumentFilter, 'sort'>
): Promise<T[]|null> {
  try {
    const documentsData: T[] = []

    // https://firebase.google.com/docs/firestore/query-data/queries#in_not-in_and_array-contains-any
    if (documentIds.length <= 10) {
      const colRef = collection(database, collectionName)
      const queryConstraints: QueryConstraint[] = [
        where(documentId(), 'in', documentIds)
      ]

      if (documentFilter?.sort) {
        for (const { fieldName, direction } of documentFilter.sort) {
          queryConstraints.push(orderBy(fieldName, direction))
        }
      }

      const docsQuery = query(colRef, ...queryConstraints)
      const querySnap = await getDocs(docsQuery)

      querySnap.forEach(document => {
        const docData = document.data()
        const documentData = { id: document.id, ...docData } as T
        documentsData.push(documentData)
      })

      console.log('[getDocuments] Firestore result (with query)', documentsData)

      return documentsData
    }

    const documentPromises = documentIds.map(documentId => {
      return getDocument<T>(database, collectionName, documentId)
    })
    const documentResults = await Promise.all(documentPromises)
    const totalDocResults = documentResults.length

    for (let index = 0; index < totalDocResults; index++) {
      const documentResult = documentResults[index]

      if (!documentResult) {
        console.warn('[getDocuments] Firestore document not exists', {
          index
        })
        continue
      }

      documentsData.push(documentResult)
    }

    if (documentFilter?.sort) {
      const { fieldName, direction } = documentFilter.sort[0]

      documentsData.sort((docDataA, docDataB) => {
        const valueA: number|string = docDataA[fieldName]
        const valueB: number|string = docDataB[fieldName]
        const isAscendant = direction === 'asc'

        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return isAscendant ? valueA - valueB : valueB - valueA
        }

        if (
          typeof valueA === 'string' && !isNaN(Date.parse(valueA)) &&
          typeof valueB === 'string' && !isNaN(Date.parse(valueB))
        ) {
          const dateA = new Date(valueA)
          const dateB = new Date(valueB)
          const timestampA = dateA.getTime()
          const timestampB = dateB.getTime()

          return isAscendant ? timestampA - timestampB : timestampB - timestampA
        }

        if (valueA < valueB) {
          return isAscendant ? -1 : 1
        }

        if (valueA > valueB) {
          return isAscendant ? 1 : -1
        }

        return 0
      })
    }

    console.log('[getDocuments] Firestore result', documentsData)

    return documentsData
  } catch (error) {
    console.error('[getDocuments] Firestore error', error)
    return null
  }
}

/**
 * Find multiple documents into a collection with filters
 * @see https://firebase.google.com/docs/reference/js/firestore_.md#where
 * @see https://firebase.google.com/docs/reference/js/firestore_.collectionreference
 * @see https://firebase.google.com/docs/reference/js/firestore_.md#query
 * @see https://firebase.google.com/docs/reference/js/firestore_.md#getdocs
 * @param {Firestore} database
 * @param {string} collectionName
 * @param {DocumentFilter} [documentFilter]
 * @returns {Promise<Documents<T>|null>}
 */
 export async function findDocuments <T extends Document>(
  database: Firestore,
  collectionName: string,
  documentFilter?: DocumentFilter
): Promise<Documents<T>|null> {
  try {
    const documentsData: T[] = []
    const queryConstraints: QueryConstraint[] = []

    if (documentFilter?.where) {
      for (const { fieldName, condition, value } of documentFilter.where) {
        queryConstraints.push(
          where(fieldName, condition, value)
        )
      }
    }

    if (documentFilter?.sort) {
      for (const { fieldName, direction } of documentFilter.sort) {
        queryConstraints.push(
          orderBy(fieldName, direction)
        )
      }
    }

    if (documentFilter?.paginate) {
      const { maximum, startDoc, endDoc } = documentFilter.paginate

      if (startDoc) {
        queryConstraints.push(
          startAfter(startDoc)
        )
      }

      if (endDoc) {
        queryConstraints.push(
          endAt(endDoc)
        )
      }

      if (maximum) {
        queryConstraints.push(
          limit(maximum)
        )
      }
    }

    const colRef = collection(database, collectionName)
    const docsQuery = query(colRef, ...queryConstraints)
    const querySnap = await getDocs(docsQuery)

    querySnap.forEach(document => {
      const docData = document.data()
      const documentData = { id: document.id, ...docData } as T

      documentsData.push(documentData)
    })

    const totalDocuments = querySnap.docs.length
    const documents: Documents<T> = {
      documents: documentsData,
      lastDocument: totalDocuments > 1
        ? querySnap.docs[totalDocuments - 1]
        : (querySnap.docs?.[0] || null)
    }

    console.log('[findDocuments] Firestore result', documents)

    return documents
  } catch (error) {
    console.error('[findDocuments] Firestore error', error)
    return null
  }
}

/**
 * Count documents into a collection with filters
 * @see https://firebase.google.com/docs/firestore/query-data/aggregation-queries#use_the_count_aggregation
 * @param {Firestore} database
 * @param {string} collectionName
 * @param {Pick<DocumentFilter, 'where'>} [documentFilter]
 * @returns {Promise<number|null>}
 */
export async function countDocuments (
  database: Firestore,
  collectionName: string,
  documentFilter?: Pick<DocumentFilter, 'where'>
): Promise<number|null> {
  try {
    const queryConstraints: QueryConstraint[] = []

    if (documentFilter?.where) {
      for (const { fieldName, condition, value } of documentFilter.where) {
        queryConstraints.push(
          where(fieldName, condition, value)
        )
      }
    }

    const colRef = collection(database, collectionName)
    const docsQuery = query(colRef, ...queryConstraints)
    const querySnap = await getCountFromServer(docsQuery)
    const docData = querySnap.data()

    console.log('[countDocuments] Firestore result', docData)

    return docData.count
  } catch (error) {
    console.error('[countDocuments] Firestore error', error)
    return null
  }
}

/**
 * Listen a document changes into a collection
 * @see https://firebase.google.com/docs/reference/js/firestore_#doc
 * @see https://firebase.google.com/docs/firestore/query-data/listen
 * @param {Firestore} database
 * @param {string} collectionName
 * @param {string} documentId
 * @param {ListenerCallback<T>} callback
 * @returns {Promise<void>}
 */
export async function listenDocumentChanges <T extends Document>(
  database: Firestore,
  collectionName: string,
  documentId: string,
  callback: ListenerCallback<T>
): Promise<void> {
  try {
    const docRef = doc(database, collectionName, documentId)
    const unsubscribe = onSnapshot(docRef, docSnap => {
      const docId = docSnap.id
      const docData = docSnap.data()

      if (!docData) {
        console.warn('[listenDocumentChanges] Firestore document not exists yet', docId)
      }

      const documentData = docData
        ? { id: docId, ...docData } as T
        : null

      callback(documentData, unsubscribe)

      console.log('[listenDocumentChanges] Firestore result', documentData)
    }, error => {
      callback(null, unsubscribe, error)
    })
  } catch (error) {
    console.error('[listenDocumentChanges] Firestore error', error)
  }
}
